<template>
  <div class="home">
    <div class="header">
      <div class="upload-box">
        <span>批量上传</span>
        <input type="file" accept=".xlsx" placeholder="批量上传" @change="handleFileChange" />
      </div>
      <div class="search-box">
        <el-input v-model="name" @change="handleSearch" placeholder="用户昵称" />
        <el-input v-model="certificate_ids" @change="handleSearch" placeholder="证书编号" />
        <el-input v-model="identity_card" @change="handleSearch" placeholder="身份证号" />
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" header-row-class-name="header-row">
      <el-table-column prop="name" label="用户昵称" width="100"></el-table-column>
      <el-table-column prop="identity_card" label="身份证号" width="170"></el-table-column>
      <el-table-column prop="phone_num" label="账户绑定手机号" width="120"></el-table-column>
      <el-table-column label="学习进度" width="120">
        <template slot-scope="scope">
          <div>{{ scope.row.schedule }}%</div>
        </template>
      </el-table-column>
      <el-table-column prop="duration" label="累计学习时长"></el-table-column>
      <el-table-column prop="certificate_time" label="客户创建时间"></el-table-column>
      <el-table-column prop="certificate_ids" label="证书编号"></el-table-column>
      <el-table-column prop="certificate_name" label="证书名称"></el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button @click="editAction(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="delAction(scope.row)" type="text" size="small" style="color: red;">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="20" :current-page.sync="page"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!-- <el-button @click="goSearch">测试测试跳转</el-button> -->
    <el-dialog title="" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
      <div class="edit-box">
        <div><span>用户昵称</span><el-input v-model="editModel.name" placeholder="用户昵称" /></div>
        <div><span>身份证号</span><el-input v-model="editModel.identity_card" placeholder="身份证号" /></div>
        <div><span>账户绑定手机号</span><el-input v-model="editModel.phone_num" placeholder="账户绑定手机号" /></div>
        <div><span>学习进度</span><el-input v-model="editModel.schedule" placeholder="学习进度" /></div>
        <div><span>累计学习时长</span><el-input v-model="editModel.duration" placeholder="累计学习时长" /></div>
        <div><span>选择客户创建时间</span><el-date-picker v-model="editModel.certificate_time" type="date" placeholder="选择客户创建时间"
            value-format="yyyy年MM月dd日" style="width: 100%;">
          </el-date-picker></div>
        <div><span>证书编号</span><el-input v-model="editModel.certificate_ids" placeholder="证书编号" /></div>
        <div><span>证书名称</span><el-input v-model="editModel.certificate_name" placeholder="证书名称" /></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveInfoAction">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { getInfoList, delInfo, saveInfo } from "../utils/http"
import axios from 'axios'

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      tableData: [],
      // 编辑弹窗
      dialogVisible: false,
      total: 0,
      page: 1,
      name: '',
      identity_card: '',
      certificate_ids: '',
      editModel: {}
    }
  },
  mounted() {
    this.listRequest();
  },
  methods: {
    goSearch() {
      this.$router.push({ name: 'search' });
    },
    handleCurrentChange() {
      this.listRequest();
    },
    handleSearch() {
      this.page = 1;
      this.listRequest();
    },
    async listRequest() {
      const res = await getInfoList({
        page: this.page,
        name: this.name,
        identity_card: this.identity_card,
        certificate_ids: this.certificate_ids
      })
      if (res.code != 200) {
        return this.$message.error(res.msg);
      }
      this.tableData = res.data.data;
      this.total = res.data.total;
    },
    // 上传文件
    handleFileChange(event) {
      var selectedFile = event.target.files[0];
      const formData = new FormData();
      formData.append('file', selectedFile);
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      });
      axios.post('/convenient_api/CertificateInfo/save_batch_info', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        console.log(response);
        loading.close();
        this.$message.success('上传成功');
        this.page = 1;
        this.listRequest();
      }).catch(() => {
        loading.close();
        this.$message.error('上传失败');
      });
    },
    // 编辑
    editAction(model) {
      this.editModel = {...model};
      this.dialogVisible = true;
    },
    async saveInfoAction() {
      if (this.editModel.name == '') {
        return this.$message.error('用户昵称不能为空');
      }
      if (this.editModel.phone_num == '') {
        return this.$message.error('账户绑定手机号不能为空');
      }
      if (this.editModel.schedule == '') {
        return this.$message.error('学习进度不能为空');
      }
      if (this.editModel.duration == '') {
        return this.$message.error('累计学习时长不能为空');
      }
      if (!this.editModel.create_time || this.editModel.create_time == '') {
        return this.$message.error('日期不能为空');
      }
      if (this.editModel.certificate_name == '') {
        return this.$message.error('证书名称不能为空');
      }
      if (this.editModel.certificate_ids == '') {
        return this.$message.error('证书编号不能为空');
      }
      if (this.editModel.identity_card == '') {
        return this.$message.error('身份证号不能为空');
      }
      const loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      });
      const res = await saveInfo(this.editModel);
      loading.close();
      if (res.code != 200) {
        return this.$message.error(res.msg);
      }
      this.$message.success('保存成功');
      this.dialogVisible = false;
      this.listRequest();
    },
    // 删除
    async delAction(model) {
      console.log(model);
      const res = await delInfo({ id: model.id });
      if (res.code != 200) {
        return this.$message.error(res.msg);
      }
      this.$message.success('删除成功');
      this.listRequest();
    },
  }
}
</script>

<style scoped>
.home {
  padding-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.upload-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #66b1ff;
  border-color: #66b1ff;
  color: #FFF;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.upload-box span {
  flex: 1;
}

.upload-box input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.search-box {
  display: flex;
  justify-content: right;
  gap: 16px;
}

.search-box .el-input {
  width: 200px;
}

.edit-box {
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.edit-box>div {
  display: flex;
  align-items: center;
  gap: 16px;
}
.edit-box>div>span {
  display: inline-block;
  width: 120px;
  text-align: right;
  font-size: 14px;
}
.edit-box>div>.el-date-picker,
.edit-box>div>.el-input {
  flex: 1;
}

.page-box {
  margin-top: 16px;
  display: flex;
  justify-content: right;
}
</style>
<style>
.el-table th.el-table__cell {
  background-color: #f5f7fa !important;
}

.el-dialog {
  border-radius: 8px !important;
}
</style>