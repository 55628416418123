//引入axios
import axios from 'axios'

// 创建axios实例
const axiosEp = axios.create({
  //base接口，表示请求URL的公共部分
  baseURL: '/convenient_api',
  // 超时
  timeout: 30000
  //还可以进行一些其他的配置
})

axiosEp.interceptors.request.use(config => {

  //配置请求头，以下是一个示例，设置语言为简体中文
  config.headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  // application/x-www-form-urlencoded
  //对请求数据进行处理

  return config
}, error => {
  console.log('请求拦截器错误', error)
  //请求拦截错误处理

})

axiosEp.interceptors.response.use(async (res) => {
  //根据自己的需求进行代码的编写，以下是一些示例

  // 获取错误信息
  // const message = res.data.message 

  //通过响应码的不同进行不同的处理
  // if (code === 404) {
  // }

  return res.data
}, async error => {
  //响应发生错误时的处理

  console.log('error', error)

  return false
})

export default axiosEp //导出axios实例
