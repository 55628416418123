import axiosEp from '@/utils/request'
import qs from 'qs';

// 获取证书列表
export function getInfoList(data) {
  return axiosEp({
    url: `/CertificateInfo/get_info_list`, //请求的接口地址
    method: 'get', //请求的方式
    params: data //请求的数据
  })
}

// 删除证书
export function delInfo(data) {
  console.log(data)
  return axiosEp({
    url: `/CertificateInfo/del_info`, //请求的接口地址
    method: 'post', //请求的方式
    data: qs.stringify(data) //请求的数据
  })
}

// 保存证书
export function saveInfo(data) {
  return axiosEp({
    url: `/CertificateInfo/save_info`, //请求的接口地址
    method: 'post', //请求的方式
    data: qs.stringify(data) //请求的数据
  })
}


// 查询证书
export function getInfo(data) {
  return axiosEp({
    url: `/CertificateInfo/get_info`, //请求的接口地址
    method: 'get', //请求的方式
    params: data //请求的数据
  })
}

